<template>
  <InputText
    type="text"
    v-model="localItem.name"
    placeholder="Nombre *"
    :disabled="!isEdit"
  />
  <Textarea
    v-model="localItem.description"
    :autoResize="true"
    rows="5"
    cols="30"
    placeholder="Descripción"
    :disabled="!isEdit"
  />
  <div class="a-buttons" v-if="isEdit">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="edit"
    >
      Aceptar
    </button>
  </div>
  <Toast />
</template>

<script>
import IncidentTypeAPI from "@/services/incidentTypes.js";
import UserAPI from "@/services/users.js";
export default {
  props: {
    isEdit: Boolean,
    item: Object,
  },
  data() {
    return {
      localItem: {
        name: "",
        description: "",
        active: true,
      },
      isLoading: false,
    };
  },
  emits: ["onSuccess", "onExit"],
  mounted() {
    this.localItem = this.item;
  },
  computed: {
    isValid() {
      return this.localItem.name != "" && this.localItem.description != "";
    },
  },
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    edit() {
      this.isLoading = true;

      IncidentTypeAPI.update(this.item.id, this.localItem)
        .then(() => {
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.edit();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
};
</script>

<style></style>
